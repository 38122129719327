import { Link } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout/"
import { SEO } from "../components/SEO"

export default function NotFound() {
  return (
    <Layout>
      <SEO title="Niet gevonden" />
      <h1>Pagina niet gevonden</h1>
      <p>
        Sorry, de pagina bestaat niet meer. <Link to="/">Naar home</Link>
      </p>
    </Layout>
  )
}
